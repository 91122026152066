import { useCallback, useContext, useMemo, useState } from 'react'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import Select, { IOption } from 'components/Inputs/Select'
import ApplicationFormContext from './ApplicationFormContext'
import OptionalHeading from './OptionalHeading'
import ApplicationFormFieldContainer from './ApplicationFormFieldContainer'
import { LabelVisibility } from 'components/Forms'
import { colors, spacings } from 'stylesheets/theme'
import './boost_prioritization.scss'
import Container from 'components/Container'
import { css } from '@emotion/react'
import { TExpertiseOption } from './types'
import Summary from './Summary'
import { Paragraph } from 'components/Typography'
import { boldedListHtml } from './helper'

interface BoostPrioritizationProps {
  name?: string
  boostList?: IOption[]
  expertiseList?: TExpertiseOption[]
  seniorityList?: IOption[]
  userData?: {
    locations: any[]
    departments: any[]
  }
}

const dropdownStyle = {
  control: {
    width: '335px',
  },
  menu: {
    margin: 0,
    width: '335px',
  },
  menuList: {
    padding: 0,
    'max-height': `calc(97px * 2.75)`, // 97px per option, 2.75 options
    'overflow-y': 'scroll !important',
  },
  option: {
    display: 'flex',
    'flex-direction': 'column',
    gap: spacings.grid_gap_basis,
    'flex-wrap': 'wrap',
    padding: spacings.grid_gap_basis,
    'white-space': 'normal',
    'border-bottom': `0.5px solid ${colors.borders.gray}`,
  },
}

const specificBoostInputStyle = css({
  '&.input-component-wrapper .label': {
    fontWeight: 'normal',
  },
})

export default function BoostPrioritization({
  name,
  boostList,
  expertiseList,
  seniorityList,
  userData,
}: BoostPrioritizationProps): JSX.Element {
  const { formData, updateRawFormData, isMobile, onSelfBoostEdit } = useContext(
    ApplicationFormContext,
  )
  // We have this so that we can keep track of the current boost selected,
  // and set showSpecificBoostInput if the current boost is one of the preferences
  const [currentBoost, setCurrentBoost] = useState(formData[name] || '')
  const [boostedFieldChoice, setBoostedFieldChoice] = useState(
    formData['boosted-field-choice'] || null,
  )

  const locationOptions = useMemo(() => {
    return userData.locations.map((location) => ({
      value: location,
      label: [location.city, location.state].filter((l) => !!l).join(', '),
    }))
  }, [userData])

  const departmentOptions = useMemo(() => {
    return userData.departments.map((department) => ({
      value: department,
      label: department,
    }))
  }, [userData])

  const showSpecificBoostInput = useMemo(() => {
    switch (currentBoost) {
      case 'important-tenure':
      case 'important-location':
      case 'important-expertise':
      case 'important-business-unit':
        return true
      default:
        return false
    }
  }, [currentBoost])

  const specificBoostOptions = useMemo(() => {
    switch (currentBoost) {
      case 'important-tenure':
        if (formData['industry-years'] == '0-2') {
          return seniorityList.filter(
            (seniority) => parseInt(seniority.value) >= 3,
          )
        } else if (formData['industry-years'] == '20+') {
          return seniorityList.filter(
            (seniority) => parseInt(seniority.value) <= 3,
          )
        } else {
          return seniorityList
        }
      case 'important-location':
        return locationOptions
      case 'important-expertise':
        return expertiseList
      case 'important-business-unit':
        return departmentOptions
      default:
        return []
    }
  }, [currentBoost, expertiseList, seniorityList])

  const specificBoostInputLabel = useMemo(() => {
    switch (currentBoost) {
      case 'important-tenure':
        return 'seniority'
      case 'important-location':
        return 'location'
      case 'important-expertise':
        return 'expertise'
      case 'important-business-unit':
        return 'business unit'
      default:
        return ''
    }
  }, [currentBoost])

  const title = useMemo(() => {
    return (
      <OptionalHeading optionalText="RECOMMENDED">
        Identify what matters most to you in your mentor match
      </OptionalHeading>
    )
  }, [])

  const lifeExperiences = useMemo(() => {
    const lifeExperienceTags = formData[`life-experience-goals`] || []
    const boldedLifeExperiencesTags = boldedListHtml(
      lifeExperienceTags.map((tag) => tag.name.toLowerCase()),
    )

    if (lifeExperienceTags.length === 0) {
      return null
    }

    return `<p>You are <u>interested in learning</u> about ${boldedLifeExperiencesTags}</p>`
  }, [formData])

  const importance = useCallback((value: number) => {
    if (value === 3) {
      return 'very important'
    } else if (value === 2) {
      return 'somewhat important'
    } else if (value === 1) {
      return 'less important'
    } else {
      return 'not important'
    }
  }, [])

  const summaryList = useMemo(() => {
    const list = []
    if (formData['important-tenure'] !== undefined) {
      list.push(
        <p>
          Having a match who is <b>very senior or tenured</b> is{' '}
          <u>{importance(formData['important-tenure'])}</u> to you
        </p>,
      )
    }

    if (formData['important-location'] !== undefined) {
      list.push(
        <p>
          Having a match who is <b>located in the same geographic location</b>{' '}
          is <u>{importance(formData['important-location'])}</u> to you
        </p>,
      )
    }

    if (formData['important-business-unit'] !== undefined) {
      list.push(
        <p>
          Having a match from the <b>same department or business unit</b> is{' '}
          <u>{importance(formData['important-business-unit'])}</u> to you
        </p>,
      )
    }

    if (formData['important-expertise'] !== undefined) {
      list.push(
        <p>
          Having a match from the <b>same professional field</b> is{' '}
          <u>{importance(formData['important-expertise'])}</u> to you
        </p>,
      )
    }

    if (lifeExperiences) {
      list.push(
        <div
          dangerouslySetInnerHTML={{
            __html: lifeExperiences as string,
          }}></div>,
      )
    }

    return list.filter((item) => !!item)
  }, [formData])

  const onBoostFieldChoiceChange = useCallback(
    (boostFieldChoice) => {
      let choice = boostFieldChoice

      if (currentBoost == 'important-location') {
        choice =
          locationOptions.find(
            (location) =>
              JSON.stringify(location.value) ==
              JSON.stringify(boostFieldChoice),
          )?.label ?? ''
      } else if (currentBoost == 'important-business-unit') {
        choice =
          departmentOptions.find(
            (department) =>
              JSON.stringify(department.value) ==
              JSON.stringify(boostFieldChoice),
          )?.label ?? ''
      }

      setBoostedFieldChoice(boostFieldChoice)
      const newFormData = new FormData()
      newFormData.append('boosted-field-choice', choice as string)
      updateRawFormData(newFormData)
    },
    [
      currentBoost,
      locationOptions,
      departmentOptions,
      setBoostedFieldChoice,
      updateRawFormData,
    ],
  )

  return (
    <ApplicationStepWrapper
      title={title}
      description={
        <Paragraph>
          If you could choose <b>only one factor</b> for us to consider in
          finding you a match, what would it be?
        </Paragraph>
      }
      css={
        isMobile && {
          '.content-box': {
            '.step-question': {
              marginBottom: spacings.grid_gap_basis,
            },
          },
        }
      }
      requiredFields={
        showSpecificBoostInput ? ['boosted-field-choice-input'] : []
      }>
      <Container direction="column" alignment="start">
        <ApplicationFormFieldContainer
          labelVisibility={LabelVisibility.HIDE}
          label="Prioritization"
          fieldId={name}>
          <Select<IOption>
            name={name}
            value={currentBoost}
            options={boostList}
            showDescription
            styleOverrides={{
              ...dropdownStyle,
            }}
            onChange={(data) => {
              // Update the raw form data here because onNext will not be called if this page is the last page
              const newFormData = new FormData()
              newFormData.append(name, data[name] as string)
              updateRawFormData(newFormData)
              setCurrentBoost(data[name] as string)
              setBoostedFieldChoice(null)
            }}
            css={{
              resize: 'none',
              width: isMobile ? '100%' : 463,
            }}
            isSearchable={!isMobile}
          />
        </ApplicationFormFieldContainer>
        {showSpecificBoostInput && (
          <ApplicationFormFieldContainer
            css={specificBoostInputStyle}
            labelVisibility={LabelVisibility.SHOW}
            required
            label={`Specify ${specificBoostInputLabel}`}
            // Using a different field name here because value could be an object and we need to store it as a string in formData
            fieldId={'boosted-field-choice-input'}>
            <Select<IOption>
              name={'boosted-field-choice-input'}
              value={boostedFieldChoice}
              options={specificBoostOptions}
              styleOverrides={{
                ...dropdownStyle,
              }}
              onChange={(data) =>
                onBoostFieldChoiceChange(
                  data['boosted-field-choice-input'] as string,
                )
              }
              css={{
                resize: 'none',
                width: isMobile ? '100%' : 463,
              }}
              isSearchable={!isMobile}
            />
          </ApplicationFormFieldContainer>
        )}
        <Summary
          title="Summary of mentor match preferences"
          description={
            <Paragraph>
              You&apos;ve shared your preferences of what you&apos;re looking
              for in a match, and the life experiences you&apos;re interested in
              learning about. Here&apos;s a summary of what you&apos;re ideally
              looking for in a match:
            </Paragraph>
          }
          list={summaryList}
          onEdit={onSelfBoostEdit}></Summary>
      </Container>
    </ApplicationStepWrapper>
  )
}
