import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'
import { colors } from 'stylesheets/theme'
import ListIcon from 'images/icons/list_18.svg'
import SectionHeader from 'components/SectionHeader/SectionHeader'
import Container from 'components/Container/Container'
import { Paragraph } from 'components/Typography'
import Button, { Variant } from 'components/Button/Button'
import EditIcon from 'images/icons/edit_with_underline.svg'
import EmptyState from 'components/EmptyState/EmptyState'
import emptyStateImg from 'images/mentor_match_preference.png'

const grayBox = css({
  backgroundColor: colors.backgrounds.page,
  padding: spacings.grid_gap_basis_num * 2,
  borderRadius: spacings.radius,
  width: '100%',
})

const iconStyle = css({
  marginRight: spacings.grid_gap_basis_num / 2,
  width: 18,
  height: 18,
})

const headerStyle = css({
  gap: 0,
})

const headerTitleStyle = css({
  '.header-left .section-title': {
    color: colors.text.text_5,
  },
  marginBottom: spacings.grid_gap_basis_num,
})

const summaryFooterStyle = css({
  alignSelf: 'flex-end',
})

const bodyBoxStyle = css({
  gap: spacings.grid_gap_basis_num * 2,
  width: '100%',
  backgroundColor: colors.backgrounds.white,
  padding: spacings.grid_gap_basis_num * 2,
  ul: {
    paddingLeft: 0 /* Remove default padding */,
    marginLeft: spacings.grid_gap_basis_num * 2,
  },
  li: {
    listStyleType: 'disc',
    marginBottom: spacings.grid_gap_basis_num * 2,
  },
})

const editIconCss = css({
  marginRight: spacings.grid_gap_basis_num / 2,
  textDecoration: 'underline',
})

export interface ISummaryProps {
  title: string
  description: JSX.Element
  list: JSX.Element[]
  onEdit?(): void
}

export default function Summary({
  title,
  description,
  list,
  onEdit = () => {},
}: ISummaryProps): JSX.Element {
  return (
    <Container css={grayBox} direction="column" alignment="start">
      <Container className="summary-header" css={headerStyle} alignment="start">
        <ListIcon css={iconStyle} />
        <SectionHeader css={headerTitleStyle}>{title}</SectionHeader>
      </Container>
      {list.length === 0 ? (
        <Container css={bodyBoxStyle}>
          <EmptyState
            imgUrl={emptyStateImg}
            actionNode={
              <Button variant={Variant.SECONDARY} onClick={onEdit}>
                select your preferences
              </Button>
            }>
            <Paragraph>
              You haven’t shared your match preferences or the life experiences
              you’d like to learn yet. Click below to return to the preference
              pages and help us understand your ideal mentor match.
            </Paragraph>
          </EmptyState>
        </Container>
      ) : (
        <Container
          className="summary-body"
          direction="column"
          css={bodyBoxStyle}
          alignment="start">
          {description}
          <ul>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Container>
      )}

      {onEdit && (
        <Container
          className="summary-footer"
          alignment="end"
          css={summaryFooterStyle}>
          <Paragraph>If this doesn’t look right,</Paragraph>
          <Button
            variant={Variant.LINK}
            startIcon={<EditIcon css={editIconCss} />}
            onClick={onEdit}>
            Edit your responses
          </Button>
        </Container>
      )}
    </Container>
  )
}
