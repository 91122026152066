import { useContext } from 'react'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import Input from 'components/Input'
import ApplicationFormContext from './ApplicationFormContext'
import Summary, { ISummaryProps } from './Summary'
import Container from 'components/Container'

interface AdditionalDetailsProps {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  name?: string
  summaryProps?: ISummaryProps
}

export default function AdditionalDetails({
  title,
  description,
  name,
  summaryProps = null,
}: AdditionalDetailsProps): JSX.Element {
  const { formData } = useContext(ApplicationFormContext)
  return (
    <ApplicationStepWrapper title={title} description={description}>
      <Container
        direction="column"
        alignment="start"
        css={{
          width: '100%',
        }}>
        <Input
          type="textarea"
          name={name}
          defaultValue={formData[name] || null}
          css={{
            resize: 'none',
            width: '100%',
          }}
        />
        {summaryProps && <Summary {...summaryProps} />}
      </Container>
    </ApplicationStepWrapper>
  )
}
