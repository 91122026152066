import React, { useCallback, useContext, useMemo, useState } from 'react'
import PrioritizeModal from './Modals/PrioritizeModal'
import axios from 'axios'
import _ from 'lodash'
import DeprioritizeModal from './Modals/DeprioritizeModal'
import RemoveModal from './Modals/RemoveModal'
import RestoreModal from './Modals/RestoreModal'
import RemoveNoteModal from './Modals/RemoveNoteModal'
import PrioritizeNoteModal from './Modals/PrioritizeNoteModal'
import {
  IBoostableObject,
  ICommunity,
  IMentorshipApplicationLabel,
  IMentorshipExchangeApplication,
  IMentorshipExchangePackage,
} from 'types'
import { IMainTableFilters, ITableData, ITableProps } from 'components/Table'
import { IOption } from 'components/Inputs/Select'
import BoostEditModal from './Modals/BoostEditModal'
import { ITruncatedMentorshipApplication } from './types'
import { IMembershipFiltersProps } from 'components/MembershipFilters'

export enum ACTIONS {
  MANAGER_APPROVAL = 'manager_approval_status',
  PRIORITIZE = 'prioritize',
  REMOVE = 'remove',
  PRIORITIZE_NOTE = 'prioritize_note',
  DEPRIORITIZE = 'deprioritize',
  REMOVE_NOTE = 'remove_note',
  RESTORE = 'restore',
  BOOST = 'boost',
}

export const managerResponseOptions = ['Pending', 'Approved', 'Denied']

export type ManagerResponse = (typeof managerResponseOptions)[number]
export type AjaxyMethods = 'put' | 'post' | 'get'

export interface IMentorshipApplicantTables {
  pending_manager_approval?: ITableProps
  eligible?: ITableProps & {
    filterOptions: IMembershipFiltersProps['filterOptions']
  }
  prioritized?: ITableProps
  removed?: ITableProps
  matches_removed?: ITableProps
  not_yet_matched?: ITableProps
  all_not_yet_matched?: ITableProps
  matches_review?: ITableProps
  matches_accepted?: ITableProps
  activity_logs?: ITableProps
}

export interface IMentorshipApplicantCounts {
  pending_approval: number
  eligible: number
  prioritized: number
  removed: number
  review: number
  accepted: number
  not_yet_matched: number
  need_reminders?: number
  pending_notification?: number
}

export interface IMentorshipActionUrls {
  start_matching?: string
  start_rematching?: string
  send_reminder?: string
  export_emails?: string
  edit_program?: string
  view_application?: string
  extend_deadline?: string
  invite_users?: string
  update_applicant?: string
  update_match?: string
  invitee_import_status_csv?: string
  invitee_import_log_csv?: string
  invite_additional_users?: string
  send_reminders?: string
  need_reminding?: string
  reset_previously_matched_for_all?: string
  remove_all_boosts?: string
  reject_all_matches?: string
  reject_selected_matches?: string
  accept_selected_matches?: string
  rematch_selected?: string
  mark_program_ready?: string
  reopen_program?: string
  start_rewording?: string
  audience_sample_csv?: string
  send_manager_approval_reminder?: string
  communities_search?: string
  notify_participants?: string
  reopen_matching?: string
  copy_applications_to?: string
  create_application_label?: string
  edit_application_labels?: string
  assign_program_application_label?: string
  serach_application_labels?: string
  custom_matching_rules_page?: string
  accepted_matches_rule_summary?: string
}

export interface IMentorshipExchangeDetailsProps {
  exchange: IMentorshipExchangePackage
  tables: IMentorshipApplicantTables
  counts: IMentorshipApplicantCounts
  urls: IMentorshipActionUrls
  labels: IMentorshipApplicationLabel[]
  errors?: string[]
  messages?: string[]
  possibleCohorts?: Partial<ICommunity>[]
  boostList?: IOption[]
  seniorityList?: IOption[]
  expertiseList?: IOption[]
  userData?: {
    locations: any[]
    departments: any[]
  }
}

interface IMentorshipExchangeDetailsContext
  extends IMentorshipExchangeDetailsProps {
  setDataExchange: (value: IMentorshipExchangePackage) => void
  setDataTables: (value: IMentorshipApplicantTables) => void
  setDataCounts: (value: IMentorshipApplicantCounts) => void
  setDataUrls: (value: IMentorshipActionUrls) => void
  setPrioritizeModalObject: (value: IMentorshipExchangeApplication) => void
  onSetManagerApprovalResponse: (
    id: number,
    manager_approval_response: number,
  ) => void
  setRemoveModalObject: (value: IMentorshipExchangeApplication) => void
  setPrioritizeNoteModalObject: (value: IMentorshipExchangeApplication) => void
  setDeprioritizeModalObject: (value: IMentorshipExchangeApplication) => void
  setRemoveNoteModalObject: (value: ITruncatedMentorshipApplication) => void
  setRestoreModalObject: (value: ITruncatedMentorshipApplication) => void
  setBoostEditModalObject: (value: IBoostableObject) => void
  onSetMatchFavorite: (matchId: number, favorite: boolean) => void
  updateTableData: (tableName: string, data: ITableData) => void
  updateTableFilters: (tableName: string, filters: IMainTableFilters) => void
  ajaxWithUpdates: (
    url: string,
    params: Record<string, any>,
    method: AjaxyMethods,
    onUpdate?: (data: Record<string, any>) => void,
    onError?: (data: Record<string, any>) => void,
  ) => void
  loading: boolean
  matchesLoading: Record<number, boolean>
  setMatchLoading: (matchId: string) => void
  setMatchStoppedLoading: (matchId: string) => void
  displayPendingApproval: boolean
}

interface IMentorshipExchangeDetailsContextProps
  extends IMentorshipExchangeDetailsProps {
  children: React.ReactNode
}

type TableSettingsExtractorFunction = (table: ITableProps) => any

const MentorshipExchangeDetailsContext = React.createContext(
  {} as IMentorshipExchangeDetailsContext,
)

export const MentorshipExchangeDetailsContextProvider = ({
  children,
  exchange,
  tables,
  counts,
  urls,
  labels,
  errors,
  possibleCohorts,
  boostList,
  seniorityList,
  expertiseList,
  userData,
}: IMentorshipExchangeDetailsContextProps): JSX.Element => {
  const [dataLoading, setDataLoading] = useState(false)
  const [matchesLoading, setMatchesLoading] = useState<Record<number, boolean>>(
    {},
  )
  const [dataExchange, setDataExchange] = useState(exchange)
  const [dataTables, setDataTables] = useState(tables)
  const [dataCounts, setDataCounts] = useState(counts)
  const [dataUrls, setDataUrls] = useState(urls)
  const [prioritizeModalObject, setPrioritizeModalObject] =
    useState<null | IMentorshipExchangeApplication>(null)
  const [removeModalObject, setRemoveModalObject] =
    useState<null | IMentorshipExchangeApplication>(null)
  const [prioritizeNoteModalObject, setPrioritizeNoteModalObject] =
    useState<null | IMentorshipExchangeApplication>(null)
  const [deprioritizeModalObject, setDeprioritizeModalObject] =
    useState<null | IMentorshipExchangeApplication>(null)
  const [removeNoteModalObject, setRemoveNoteModalObject] =
    useState<null | ITruncatedMentorshipApplication>(null)
  const [restoreModalObject, setRestoreModalObject] =
    useState<null | ITruncatedMentorshipApplication>(null)
  const [boostEditModalObject, setBoostEditModalObject] =
    useState<null | IBoostableObject>(null)

  const displayPendingApproval = useMemo(
    () =>
      exchange.data.manager_approval_required && counts.pending_approval > 0,
    [exchange, counts],
  )
  /**
   * Make the request with given url, and params, then call given callback when request is completed
   */
  const onAction = useCallback(
    (url, params = {}, callback = () => {}) => {
      axios
        .put(url, {
          params: params,
          authenticity_token: window.authenticity_token,
        })
        .then(
          ({
            data: {
              exchange,
              tables,
              counts,
              urls,
              errors = [],
              messages = [],
            },
          }: {
            data: IMentorshipExchangeDetailsProps
          }) => {
            try {
              if (errors.length) {
                window.flash(errors.join(' '), 'error')
              }
              if (messages.length) {
                window.flash(messages.join(' '), 'success')
              }
              // after we receive a response, update the data
              setDataExchange(exchange)
              setDataTables(tables)
              setDataCounts(counts)
              setDataUrls(urls)
            } catch {}
            callback()
          },
        )
    },
    [setDataExchange, setDataTables, setDataCounts, setDataUrls, dataTables],
  )

  const updateTableData = useCallback(
    (tableName: string, data: ITableData) => {
      if (!_.isNil(dataTables[tableName as keyof IMentorshipApplicantTables])) {
        dataTables[tableName].tableData = data
      }
      setDataTables(_.cloneDeep(dataTables))
    },
    [dataTables, setDataTables],
  )

  const updateTableFilters = useCallback(
    (tableName: string, filters: IMainTableFilters) => {
      if (!_.isNil(dataTables[tableName as keyof IMentorshipApplicantTables])) {
        dataTables[tableName].selectedFilters = filters
      }
      setDataTables(_.cloneDeep(dataTables))
    },
    [dataTables, setDataTables],
  )

  const ajaxWithUpdates = useCallback(
    _.debounce(
      (
        url: string,
        params: Record<string, any> = {},
        method: AjaxyMethods = 'post',
        onUpdate?: (data: Record<string, any>) => void,
        onError?: (data: Record<string, any>) => void,
      ) => {
        // show loading state for all the tables
        setDataLoading(true)

        // perform the request
        axios[method](url, {
          params: addStandardParams(params),
          authenticity_token: window.authenticity_token,
        })
          .then(({ data }: { data: Record<string, any> }) => {
            try {
              const {
                exchange,
                tables,
                counts,
                urls,
                errors = [],
                messages = [],
              } = data as IMentorshipExchangeDetailsProps

              if (errors.length) {
                window.flash(errors.join(' '), 'error')
              }
              if (messages.length) {
                window.flash(messages.join(' '), 'success')
              }

              // after we receive a response, update the data
              setDataExchange(exchange)
              setDataTables(tables)
              setDataCounts(counts)
              setDataUrls(urls)

              onUpdate && onUpdate(data)
            } catch {}

            // stop showing the loading state
            setDataLoading(false)
          })
          .catch(() => {
            setDataLoading(false)
            onError && onError(params)
          })
      },
      300,
    ),
    [dataTables],
  )

  const updateApplicant = async (
    params,
    onUpdate = () => {},
    onError = () => {},
  ) => {
    ajaxWithUpdates(dataUrls.update_applicant, params, 'put', onUpdate, onError)
  }
  const updateApplicantData = (
    applicant_action: ACTIONS,
    data: any,
    onUpdate = () => {},
    onError = () => {},
  ) => {
    const raw = {
      ...data,
      applicant_action,
    }
    updateApplicant(raw, onUpdate, onError)
  }

  const extractTablesSettings = useCallback(
    (withTable: TableSettingsExtractorFunction) => {
      const current: Record<string, any> = {}
      Object.keys(dataTables).forEach((key: string) => {
        current[key] = withTable(dataTables[key])
      })
      return current
    },
    [dataTables],
  )

  const getCurrentPages = useCallback(() => {
    return extractTablesSettings((table: ITableProps): any => {
      return table.tableData?.paginator?.current_page ?? 1
    })
  }, [dataTables])

  const getOrderingParams = useCallback(() => {
    return extractTablesSettings((table: ITableProps): any => {
      return {
        sort_dir: table?.selectedFilters?.sort_dir ?? 'asc',
        sort_col: table?.selectedFilters?.sort_col,
      }
    })
  }, [dataTables])

  const getHideKeys = useCallback(() => {
    return extractTablesSettings((table: ITableProps): any => {
      return {
        hide: table?.selectedFilters?.hide,
      }
    })
  }, [dataTables])

  const addStandardParams = useCallback(
    (core: Record<string, any>) => {
      core['current_page'] = getCurrentPages()
      core['sorting'] = getOrderingParams()
      core['hide'] = getHideKeys()

      return core
    },
    [dataTables],
  )

  const setMatchLoading = useCallback(
    (matchId) => {
      const newMatchesLoading = { ...matchesLoading }
      newMatchesLoading[matchId] = true
      setMatchesLoading(newMatchesLoading)
    },
    [setMatchesLoading, matchesLoading],
  )

  const setMatchStoppedLoading = useCallback(
    (matchId) => {
      const newMatchesLoading = { ...matchesLoading }
      newMatchesLoading[matchId] = false
      setMatchesLoading(newMatchesLoading)
    },
    [setMatchesLoading, matchesLoading],
  )

  /**
   * Update match data and set loading states as request is being completed
   */
  const updateMatch = async (params) => {
    const matchParams = addStandardParams(params)

    setMatchLoading(matchParams.match_id)

    onAction(dataUrls.update_match, matchParams, () =>
      setMatchStoppedLoading(matchParams.match_id),
    )
  }

  const onSetManagerApprovalResponse = useCallback(
    (id: number, manager_approval_response: number) => {
      updateApplicantData(
        ACTIONS.MANAGER_APPROVAL,
        addStandardParams({
          id,
          manager_approval_status: manager_approval_response,
        }),
      )
    },
    [addStandardParams],
  )

  // Prioritization & Deprioritization
  const onClosePrioritizeModal = () => {
    setPrioritizeModalObject(null)
  }
  const onSavePrioritizeModal = (id: number, text: string) => {
    updateApplicantData(
      ACTIONS.PRIORITIZE,
      addStandardParams({
        id,
        prioritize_note: text,
      }),
    )
    setPrioritizeModalObject(null)
  }

  const onClosePrioritizeNoteModal = () => {
    setPrioritizeNoteModalObject(null)
  }
  const onSavePrioritizeNoteModal = (id: number, text: string) => {
    updateApplicantData(
      ACTIONS.PRIORITIZE_NOTE,
      addStandardParams({
        id,
        prioritize_note: text,
      }),
    )
    setPrioritizeNoteModalObject(null)
  }

  const onCloseDeprioritizeModal = () => {
    setDeprioritizeModalObject(null)
  }
  const onSaveDeprioritizeModal = (id: number) => {
    updateApplicantData(
      ACTIONS.DEPRIORITIZE,
      addStandardParams({
        id,
      }),
    )
    setDeprioritizeModalObject(null)
  }

  // Removal & Restoration
  const onCloseRemoveModal = () => {
    setRemoveModalObject(null)
  }
  const onSaveRemoveModal = (id: number, text: string) => {
    setMatchLoading(id)
    updateApplicantData(
      ACTIONS.REMOVE,
      addStandardParams({
        id,
        remove_note: text,
      }),
      () => setMatchStoppedLoading(id),
      () => setMatchStoppedLoading(id),
    )
    setRemoveModalObject(null)
  }

  const onCloseRemoveNoteModal = () => {
    setRemoveNoteModalObject(null)
  }
  const onSaveRemoveNoteModal = (id: number, text: string) => {
    setMatchLoading(id)
    updateApplicantData(
      ACTIONS.REMOVE_NOTE,
      addStandardParams({
        id,
        remove_note: text,
      }),
      () => setMatchStoppedLoading(id),
      () => setMatchStoppedLoading(id),
    )
    setRemoveNoteModalObject(null)
  }

  const onCloseRestoreModal = () => {
    setRestoreModalObject(null)
  }

  const onSaveRestoreModal = (id: number) => {
    setMatchLoading(id)
    updateApplicantData(
      ACTIONS.RESTORE,
      addStandardParams({
        id,
      }),
      () => setMatchStoppedLoading(id),
      () => setMatchStoppedLoading(id),
    )
    setRestoreModalObject(null)
  }

  const onSetMatchFavorite = useCallback(
    (matchId: number, favorite: boolean) => {
      updateMatch({ match_id: matchId, favorite })
    },
    [updateMatch],
  )

  const onCloseBoostEditModal = () => {
    setBoostEditModalObject(null)
  }
  const onSaveBoostEditModal = (
    id: number,
    value: string,
    boostChoice?: string,
  ) => {
    setMatchLoading(id)
    updateApplicantData(
      ACTIONS.BOOST,
      addStandardParams({
        id,
        boost_field: value,
        boost_field_choice: boostChoice,
      }),
      () => setMatchStoppedLoading(id),
      () => setMatchStoppedLoading(id),
    )
    setBoostEditModalObject(null)
  }

  const modals = [
    <PrioritizeModal
      key="prioritize_modal"
      object={prioritizeModalObject}
      isOpen={!!prioritizeModalObject}
      onClose={onClosePrioritizeModal}
      onSave={onSavePrioritizeModal}></PrioritizeModal>,
    <RemoveModal
      key="remove_modal"
      object={removeModalObject}
      isOpen={!!removeModalObject}
      onClose={onCloseRemoveModal}
      onSave={onSaveRemoveModal}></RemoveModal>,
    <PrioritizeNoteModal
      key="prioritize_note_modal"
      object={prioritizeNoteModalObject}
      isOpen={!!prioritizeNoteModalObject}
      onClose={onClosePrioritizeNoteModal}
      onSave={onSavePrioritizeNoteModal}></PrioritizeNoteModal>,
    <DeprioritizeModal
      key="deprioritize_modal"
      object={deprioritizeModalObject}
      isOpen={!!deprioritizeModalObject}
      onClose={onCloseDeprioritizeModal}
      onSave={onSaveDeprioritizeModal}></DeprioritizeModal>,
    <RemoveNoteModal
      key="remove_note_modal"
      object={removeNoteModalObject}
      isOpen={!!removeNoteModalObject}
      onClose={onCloseRemoveNoteModal}
      onSave={onSaveRemoveNoteModal}></RemoveNoteModal>,
    <RestoreModal
      key="restore_modal"
      object={restoreModalObject}
      isOpen={!!restoreModalObject}
      onClose={onCloseRestoreModal}
      onSave={onSaveRestoreModal}></RestoreModal>,
    <BoostEditModal
      key="boost_modal"
      object={boostEditModalObject}
      isOpen={!!boostEditModalObject}
      onClose={onCloseBoostEditModal}
      onSave={onSaveBoostEditModal}></BoostEditModal>,
  ]

  return (
    <MentorshipExchangeDetailsContext.Provider
      value={{
        loading: dataLoading,
        matchesLoading,
        setMatchLoading,
        setMatchStoppedLoading,
        exchange: dataExchange,
        tables: dataTables,
        counts: dataCounts,
        urls: dataUrls,
        labels,
        setDataExchange,
        setDataTables,
        setDataCounts,
        setDataUrls,
        setPrioritizeModalObject,
        setRemoveModalObject,
        setPrioritizeNoteModalObject,
        setDeprioritizeModalObject,
        onSetManagerApprovalResponse,
        setRemoveNoteModalObject,
        setRestoreModalObject,
        setBoostEditModalObject,
        onSetMatchFavorite,
        updateTableData,
        updateTableFilters,
        ajaxWithUpdates,
        boostList,
        seniorityList,
        expertiseList,
        userData,
        possibleCohorts,
        displayPendingApproval,
      }}>
      <div className="errors">{errors}</div>
      {children}
      {modals}
    </MentorshipExchangeDetailsContext.Provider>
  )
}

export const useMentorshipExchangeDetailsContext =
  (): IMentorshipExchangeDetailsContext =>
    useContext(MentorshipExchangeDetailsContext)
