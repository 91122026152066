export function boldedListHtml(
  list: string[],
  capitalizeFirstLetter = false,
  capitalizeKeys = false,
): string {
  let words = list
    .map((item, index) => {
      if ((capitalizeFirstLetter && index === 0) || capitalizeKeys) {
        item = item.charAt(0).toUpperCase() + item.slice(1)
      }
      return `<b>${item}</b>`
    })
    .join(', ')

  words = words.replace(/, ([^,]*)$/, ' and $1')
  return words
}
