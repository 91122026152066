import Modal, { Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Paragraph } from 'components/Typography'
import { useMentorshipExchangeDetailsContext } from '../MentorshipExchangeDetailsContext'
import { IOption } from 'components/Inputs/Select'
import Input from 'components/Input'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'
import { IBoostableObject } from 'types'

const specialModalCss = css({
  '&.small': {
    width: 696,
  },
})

const fieldCss = css({
  width: 272,
  '&.input-component-wrapper': {
    marginTop: 0,
  },
})

const fieldWrap = css({
  marginTop: spacings.grid_gap_basis_num * 2,
})

interface BoostEditModalProps {
  object: IBoostableObject
  isOpen?: boolean
  onClose: () => void
  onSave: (id: number, boostField: string, boost_choice?: string) => void
}

export default function BoostEditModal({
  object,
  isOpen = false,
  onClose,
  onSave,
}: BoostEditModalProps): JSX.Element {
  const fieldName = 'new_boost'
  const specificBoostFieldName = 'new_boost_choice'
  const { boostList, seniorityList, expertiseList, userData } =
    useMentorshipExchangeDetailsContext()

  const locationOptions = useMemo(() => {
    return userData?.locations.map((location) => ({
      value: location,
      label: [location.city, location.state].filter((l) => !!l).join(', '),
    }))
  }, [userData])

  const departmentOptions = useMemo(() => {
    return userData?.departments.map((department) => ({
      value: department,
      label: department,
    }))
  }, [userData])

  // Commenting this out because I'm not sure why we wanted to filter out location as a possible boost in first place
  // const possibleBoosts = useMemo(() => {
  //   return !!(object?.location ?? '')
  //     ? boostList
  //     : boostList.filter((item: IOption) => item.value !== 'important-location')
  // }, [object])

  const [boostField, setBoostField] = useState<string>('')

  const [boostedFieldChoice, setBoostedFieldChoice] = useState(null)

  useEffect(() => {
    setBoostField(object?.boost_field ?? '')
    if (object?.boost_field == 'important-location') {
      const locationChoice =
        locationOptions.find(
          (location) => location.label == object?.boost_field_choice,
        )?.value ?? ''
      setBoostedFieldChoice(locationChoice)
    } else if (object?.boost_field == 'important-business-unit') {
      const departmentChoice =
        departmentOptions.find(
          (department) => department.label == object?.boost_field_choice,
        )?.value ?? ''
      setBoostedFieldChoice(departmentChoice)
    } else {
      setBoostedFieldChoice(object?.boost_field_choice ?? '')
    }
  }, [object, locationOptions, departmentOptions, setBoostedFieldChoice])

  const onChangeBoost = (input: Record<string, any>) => {
    setBoostField(input[fieldName])
    setBoostedFieldChoice(null)
  }

  const onChangeBoostChoice = (input: Record<string, any>) => {
    setBoostedFieldChoice(input[specificBoostFieldName])
  }

  const onSaveModal = () => {
    if (boostField === 'important-location') {
      const locationChoice = locationOptions.find(
        (location) => location.value == boostedFieldChoice,
      )?.label
      onSave(object.id ?? 0, boostField, locationChoice)
    } else if (boostField === 'important-business-unit') {
      const departmentChoice = departmentOptions.find(
        (department) => department.value == boostedFieldChoice,
      )?.label
      onSave(object.id ?? 0, boostField, departmentChoice)
    } else {
      onSave(object.id ?? 0, boostField, boostedFieldChoice)
    }
  }

  const specificBoostOptions = useMemo(() => {
    switch (boostField) {
      case 'important-tenure':
        if (object?.industry_years == '0-2') {
          return seniorityList.filter(
            (seniority) => parseInt(seniority.value) >= 3,
          )
        } else if (object?.industry_years == '20+') {
          return seniorityList.filter(
            (seniority) => parseInt(seniority.value) <= 3,
          )
        } else {
          return seniorityList
        }
      case 'important-location':
        return locationOptions
      case 'important-expertise':
        return expertiseList
      case 'important-business-unit':
        return departmentOptions
      default:
        return []
    }
  }, [boostField, expertiseList, seniorityList, object])

  const showSpecificBoostInput = useMemo(() => {
    switch (boostField) {
      case 'important-tenure':
      case 'important-location':
      case 'important-expertise':
      case 'important-business-unit':
        return true
      default:
        return false
    }
  }, [boostField])

  const onRequestCloseModal = useCallback(() => {
    setBoostField(object?.boost_field ?? '')
    setBoostedFieldChoice(object?.boost_field_choice ?? '')
    onClose()
  }, [onClose, setBoostField, setBoostedFieldChoice, object])

  const disableConfirmButton = useMemo(() => {
    return !!boostField && showSpecificBoostInput && !boostedFieldChoice
  }, [boostField, showSpecificBoostInput, boostedFieldChoice])

  return (
    <Modal
      css={[modalStyle, specialModalCss]}
      isOpen={isOpen}
      size={Size.small}
      onRequestClose={onRequestCloseModal}
      onSave={onSaveModal}
      submitButton="Confirm"
      cancelButton="Cancel"
      title="Boost applicant"
      isSubmitDisabled={disableConfirmButton}>
      <Paragraph>
        Boosting an applicant in their preferred focus area helps streamlining
        our matching process. If they&apos;ve previously self-boosted, the new
        boost replaces their selection.
      </Paragraph>

      <div css={fieldWrap}>
        <Paragraph>Applicant&apos;s selected boost</Paragraph>
        <Input
          readOnly
          value={object?.user_boost_field_str || 'No strong preferences'}
          name="current_boost"
          hideName
          css={fieldCss}
        />
      </div>
      <div css={fieldWrap}>
        <Paragraph>New boost</Paragraph>
        <Input
          type="select"
          value={boostField}
          getOptionLabel={(option: IOption) => option.label}
          name={fieldName}
          hideName
          options={boostList}
          onChange={onChangeBoost}
          css={fieldCss}
        />
      </div>
      {showSpecificBoostInput && (
        <div css={fieldWrap}>
          <Paragraph>Specify boost</Paragraph>
          <Input
            type="select"
            required
            value={boostedFieldChoice}
            name={specificBoostFieldName}
            hideName
            options={specificBoostOptions}
            onChange={onChangeBoostChoice}
            css={fieldCss}
          />
        </div>
      )}
    </Modal>
  )
}
