import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react'
import cx from 'classnames'
import axios from 'axios'
import _ from 'lodash'

// interfaces
import { IOrderingParams, ITableProps } from 'components/Table/index'

interface ITableContext<T>
  extends Omit<ITableProps<T>, 'children' | 'secondaryActionNode'> {
  handlePaginate: (selectedParams: { selected: number }) => void
  handleOrderChange: (field: string) => void
  searchInputValue?: string
  orderingParams: IOrderingParams
  features: Record<string, boolean>
  hasEmptyState: boolean
  selectedRows?: any[]
  checkRow: (rowData: any) => void
  uncheckRow: (rowData: any) => void
  checkAllRows: () => void
  uncheckAllRows: () => void
  secondaryActionNode: JSX.Element
  leftNode?: JSX.Element
  tertiaryActionNode?: JSX.Element
  alwaysShowSearch?: boolean
}

interface ITableContextProps<T> extends Omit<ITableProps<T>, 'children'> {
  children: React.ReactNode
}

const TableContext = React.createContext({} as ITableContext<any>)

export const TableContextProvider = <T,>({
  children,
  title,
  icon,
  emptyStateImgUrl,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateChildren,
  object,
  showSelectedRowsCount,
  tableDescription,
  alwaysShowSearch = false,
  className,
  ...props
}: ITableContextProps<T>): JSX.Element => {
  const [selectedRows, setSelectedRows] = useState([])
  const [tableData, setTableData] = useState(props.tableData)
  const [tableMeta, setTableMeta] = useState(props.tableMeta)
  const [selectedFilters, setSelectedFilters] = useState(props.selectedFilters)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [orderingParams, setOrderingParams] = useState({
    sort_col: selectedFilters?.sort_col,
    sort_dir: selectedFilters?.sort_dir,
  } as IOrderingParams)
  const wrapperRef = useRef(null)

  useEffect(() => {
    setSelectedFilters({ ...selectedFilters, ...props.selectedFilters })
  }, [props.selectedFilters])

  /**
   * Returns table description that gets displayed below table title
   * If we want to show the selected rows count, the provided tableDescription string will be used as a row key
   * i.e. tableDescription = 'matches', showSelectedRowsCount = true
   *      return '5 matches selected'
   * otherwise, the given tableDescription in table props will be displayed
   */
  const description = useMemo(() => {
    if (showSelectedRowsCount && tableDescription) {
      return selectedRows.length + ' ' + tableDescription + ' selected'
    }

    return tableDescription
  }, [selectedRows, showSelectedRowsCount, tableDescription])

  const checkRow = useCallback(
    (rowData) => {
      const row = { id: rowData.id, checked: true }
      setSelectedRows([...selectedRows, row])
    },
    [setSelectedRows, selectedRows],
  )

  // Check all rows, BUT only on THIS PAGE.
  const checkAllRows = useCallback(() => {
    setSelectedRows([
      ...selectedRows,
      ...tableData.rows
        .map((row: any) => (row?.data && row.data?.id ? row.data.id : 0))
        .filter((id: number) => !!id && !selectedRows.find((r) => r.id === id))
        .map((id: number) => ({ id, checked: true })),
    ])
  }, [setSelectedRows, tableData, selectedRows])

  const uncheckRow = useCallback(
    (selectedRowData) => {
      setSelectedRows(
        selectedRows.filter((row) => row.id !== selectedRowData.id),
      )
    },
    [setSelectedRows, selectedRows],
  )

  // Uncheck all rows, BUT only on THIS PAGE
  const uncheckAllRows = useCallback(() => {
    const toDeselect = tableData.rows
      .map((row: any) => (row?.data && row.data?.id ? row.data.id : 0))
      .filter((id: number) => !!id)
    setSelectedRows(
      selectedRows
        .slice(0)
        .filter(({ id }: { id: number }) => !toDeselect.includes(id)),
    )
  }, [setSelectedRows, tableData, selectedRows])

  const maybeUpdateTableData = useCallback(
    (data) => {
      props.afterUpdateTableData && props.afterUpdateTableData(data)
    },
    [props.afterUpdateTableData],
  )
  const maybeUpdateSelectedFilters = useCallback(
    (defs) => {
      props.afterUpdateSelectedFilters &&
        props.afterUpdateSelectedFilters(
          _.merge(_.cloneDeep(selectedFilters), defs),
        )
    },
    [props.afterUpdateSelectedFilters, selectedFilters],
  )

  const getTableData = useCallback(
    async (params) => {
      const query = new URLSearchParams(window.location.search)
      if (query.get('with_debug')) {
        params['with_debug'] = 1
      }
      const {
        data: { data, meta, selectedFilters },
      } = await axios.get(props.tableMeta.url, { params })

      setTableData(data)
      maybeUpdateTableData(data)

      if (meta) {
        setTableMeta(meta)
      }

      if (selectedFilters) {
        setSelectedFilters(selectedFilters)
        maybeUpdateSelectedFilters(selectedFilters)
      }
    },
    [
      setTableData,
      maybeUpdateTableData,
      setTableMeta,
      setSelectedFilters,
      maybeUpdateSelectedFilters,
    ],
  )
  const getTableDataDebounced = useCallback(
    _.debounce(
      (params) => getTableData({ ...selectedFilters, ...params }),
      300,
    ),
    [tableData, tableMeta, selectedFilters],
  )

  const hasEmptyState = useMemo(
    () =>
      !!emptyStateImgUrl &&
      (!!object ||
        !!emptyStateTitle ||
        !!emptyStateSubtitle ||
        !!emptyStateChildren),
    [emptyStateImgUrl, object],
  )

  useEffect(() => {
    setTableData(props.tableData)
  }, [props.tableData])

  useEffect(() => {
    if (props.hiddenColumns?.length) {
      const filteredColumns = props.tableMeta?.columns.filter(
        (col) => !props.hiddenColumns?.includes(col.label),
      )
      setTableMeta({ ...props.tableMeta, columns: filteredColumns })
    } else {
      setTableMeta(props.tableMeta)
    }
  }, [props.tableMeta])

  useEffect(() => {
    if (selectedFilters) {
      const { sort_col, sort_dir } = selectedFilters
      setOrderingParams({ sort_col, sort_dir })
    }
  }, [selectedFilters])

  useEffect(() => {
    if (
      wrapperRef?.current &&
      wrapperRef.current.parentNode.dataset.reactProps
    ) {
      const currentDataSet = JSON.parse(
        wrapperRef.current.parentNode.dataset.reactProps,
      )

      wrapperRef.current.parentNode.dataset.reactProps = JSON.stringify({
        ...currentDataSet,
        tableData,
        tableMeta: props.tableMeta,
      })
    }
  }, [tableData, props.tableMeta])

  const onSearchInputChange = useCallback(
    (params) => {
      setSearchInputValue(params['filter[*]'])
      getTableDataDebounced(params)
    },
    [tableData, props.tableMeta],
  )

  const handleGetData = useCallback(
    (sort_col, sort_dir, page) => {
      const {
        sort_col: original_sort_col, // eslint-disable-line @typescript-eslint/no-unused-vars
        sort_dir: original_sort_dir, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...filters
      } = selectedFilters

      getTableData({
        ...filters,
        page,
        sort_col,
        sort_dir,
        'filter[*]': searchInputValue,
        per_page: tableData.paginator.per_page,
      })
    },
    [tableData, searchInputValue, selectedFilters],
  )

  const handlePaginate = useCallback(
    ({ selected }) => {
      const { sort_col, sort_dir } = orderingParams
      if (props.onPageChange) {
        props.onPageChange(selected)
      } else {
        handleGetData(sort_col, sort_dir, selected + 1)
      }
    },
    [orderingParams, tableData],
  )

  const handleOrderChange = (field) => {
    let { sort_col, sort_dir } = orderingParams

    if (!sort_col || sort_col !== field) {
      sort_col = field
      sort_dir = 'desc'
    } else {
      if (sort_dir === 'desc') {
        sort_dir = 'asc'
      } else {
        sort_dir = 'desc'
      }
    }

    setOrderingParams({ sort_col, sort_dir })
    if (props.onOrderChange) {
      props.onOrderChange({ sort_col, sort_dir })
    } else {
      handleGetData(sort_col, sort_dir, 1)
    }

    maybeUpdateSelectedFilters({ sort_dir, sort_col })
  }

  const handleFilterTableRows = useCallback(
    ({ hide: hideKey, show: showKey }, sortCol = selectedFilters.sort_col) => {
      const newSelectedFilters = {
        ...selectedFilters,
        hide: hideKey,
        show: showKey,
        sort_col: sortCol,
      }
      setSelectedFilters(newSelectedFilters)

      getTableData(newSelectedFilters)
      // handleGetData(sort_col, sort_dir, 1)
    },
    [getTableData, setSelectedFilters],
  )

  const secondaryActionNode = useMemo(() => {
    if (typeof props.secondaryActionNode === 'function') {
      return props.secondaryActionNode({
        handleFilterTableRows,
        selectedRows,
      }) as JSX.Element
    }
    return props.secondaryActionNode as JSX.Element
  }, [handleFilterTableRows, props.secondaryActionNode, selectedRows])

  const tertiaryActionNode = useMemo(() => {
    if (typeof props.tertiaryActionNode === 'function') {
      return props.tertiaryActionNode({
        handleFilterTableRows,
        selectedRows,
      }) as JSX.Element
    }
    return props.tertiaryActionNode as JSX.Element
  }, [handleFilterTableRows, props.secondaryActionNode, selectedRows])

  return (
    <TableContext.Provider
      value={{
        tableData,
        tableMeta,
        searchInputValue,
        orderingParams,
        handlePaginate,
        onSearchInputChange,
        handleOrderChange,
        title,
        icon,
        searchPlaceholder: props.searchPlaceholder,
        showTotal: props.showTotal,
        tableDescription: description,
        features: props.features,
        hasEmptyState,
        emptyStateImgUrl,
        object,
        emptyStateTitle,
        emptyStateSubtitle,
        emptyStateActionNode: props.emptyStateActionNode,
        emptyStateChildren,
        secondaryActionNode,
        leftNode: props.leftNode,
        tertiaryActionNode,
        infoNode: props.infoNode,
        selectedRows,
        checkRow,
        uncheckRow,
        checkAllRows,
        uncheckAllRows,
        alwaysShowSearch,
      }}>
      <div ref={wrapperRef} className={cx('GojiCustomTable-root', className)}>
        {children}
      </div>
    </TableContext.Provider>
  )
}

export const useTableContext = <
  T = Record<string, string | number | boolean>,
>(): ITableContext<T> => useContext(TableContext)
