import { Paragraph } from 'components/Typography'
import AdditionalDetails from './AdditionalDetails'
import OptionalHeading from './OptionalHeading'
import { useContext, useMemo } from 'react'
import ApplicationFormContext from './ApplicationFormContext'
import { boldedListHtml } from './helper'

export default function StrengthAdditionalDetails(): JSX.Element {
  const { formData, strengthCategories, onStrengthEdit } = useContext(
    ApplicationFormContext,
  )

  const lifeExperiences = useMemo(() => {
    const lifeExperienceTags = formData[`life-experience-strengths`] || []
    const boldedLifeExperiencesTags = boldedListHtml(
      lifeExperienceTags.map((tag) => tag.name.toLowerCase()),
    )

    if (lifeExperienceTags.length === 0) {
      return null
    }

    return `<p>You can <u>provide insights</u> about ${boldedLifeExperiencesTags}</p>`
  }, [formData])

  const strengths = useMemo(() => {
    const strengthCategoryTags = strengthCategories.map((tag) => tag.name)
    const strongest = []
    const occassional = []
    const notPrioritized = []

    strengthCategoryTags.forEach((strengthCategoryTag) => {
      Object.entries(formData).forEach(([key, value]) => {
        if (key.startsWith(strengthCategoryTag.toLowerCase())) {
          console.log(key, value)
          const readableTagName = key
            .replace(strengthCategoryTag.toLowerCase() + '-', '')
            .replace(/-/g, ' ')
            .replace(/\b\w/g, (char) => char.toLowerCase())
          console.log(readableTagName)
          if (value === 3) {
            strongest.push(readableTagName)
          } else if (value === 2) {
            occassional.push(readableTagName)
          } else if (value === 1 || value === 0) {
            notPrioritized.push(readableTagName)
          }
        }
      })
    })

    const boldedStrongest = boldedListHtml(strongest)
    const boldedOccassional = boldedListHtml(occassional)
    const boldedNotPrioritized = boldedListHtml(notPrioritized)

    const notPriotity = (() => {
      switch (notPrioritized.length) {
        case 0:
          return null
        case 1:
          // if the last word ends with 's', it is plural. i.e. 'technical skills'
          if (notPrioritized[0].endsWith('s')) {
            return `<p>Currently, ${boldedNotPrioritized} are <u>not key priorities or focus areas</u> for you</p>`
          }
          return `<p>Currently, ${boldedNotPrioritized} is <u>not a key priority or focus area</u> for you</p>`
        default:
          return `<p>Currently, ${boldedNotPrioritized} are <u>not key priorities or focus areas</u> for you</p>`
      }
    })()

    return [
      strongest.length > 0
        ? `<p>You are <u>strongest</u> in ${boldedStrongest}</p>`
        : null,
      occassional.length > 0
        ? `<p>You <u>occassionally</u> engage in ${boldedOccassional}</p>`
        : null,
      notPriotity,
    ]
  }, [formData])

  const summaryDescription = useMemo(() => {
    const strengthCategoryTags = strengthCategories.map((tag) => tag.name)
    const boldedStrengthCategories = boldedListHtml(
      strengthCategoryTags,
      false,
      true,
    )
    if (strengthCategoryTags.length === 0) {
      return null
    }

    return `<p>You’ve shared your life experiences and rated your strengths across
            key skills in the areas of ${boldedStrengthCategories}. Here’s
            a summary based on your selections:</p>`
  }, [])

  const summaryList = useMemo(() => {
    const list = [lifeExperiences, ...strengths]
      .filter((item) => !!item)
      .map((itemHtml, index) => (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: itemHtml as string }}></div>
      ))
    return list
  }, [lifeExperiences, strengths])

  return (
    <AdditionalDetails
      title={
        <OptionalHeading>
          What else should we know about your strengths?
        </OptionalHeading>
      }
      description={
        <Paragraph>
          Consider additional strengths, priorities, or insights that could help
          us better understand your abilities. Take a moment to{' '}
          <b>reflect on the summary below.</b>
        </Paragraph>
      }
      name="strengths-notes"
      summaryProps={{
        title: 'Summary of your strengths',
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: summaryDescription as string,
            }}></div>
        ),
        list: summaryList,
        onEdit: onStrengthEdit,
      }}
    />
  )
}
