import { useContext, useMemo, useState, useCallback } from 'react'
import Axios from 'axios'

import { colors, spacings } from 'stylesheets/theme'

import { Paragraph, SectionTitle } from 'components/Typography'
import Button, { Variant } from 'components/Button/Button'
import Container from 'components/Container'

import ProgressBar from './ProgressBar'
import MentorshipApplicationWrapper from './MentorshipApplicationWrapper'
import ApplicationFormContext from './ApplicationFormContext'
import ConfirmationModal from 'components/ConfirmationModal'
import { css } from '@emotion/react'

export interface IFieldValidation {
  fieldName: string
  fieldDisplayName: string
  required?: boolean
  requiredFailureMessage?: string
  pattern?: string
  patterFailureMessage?: string
  prefilter?: (value: string) => string
}

interface ApplicationStepWrapperProps {
  title: string | React.ReactNode
  description?: string | React.ReactNode
  onNextStep?: () => void
  requiredFields?: string[]
  validations?: IFieldValidation[]
  onValidate?: () => string
  children: JSX.Element
  className?: string
}

function addError(
  errors: Record<string, string[]>,
  fieldName: string,
  msg: string,
) {
  if (Array.isArray(errors[fieldName])) {
    errors[fieldName].push(msg)
  } else {
    errors[fieldName] = [msg]
  }
  return errors
}

const MOBILE_STEP_CONTAINER_HEIGHT = 112

const applicationWrapperStyle = css({
  padding: 0,
  flexDirection: 'column',
})

const defaultProgressBarStyle = css({
  width: '100%',
})

const mobileProgressBarStyle = css({
  borderRadius: 0,
  position: 'fixed',
})

const defaultStepContainer = css({
  marginTop: 70,
})

const mobileStepContainer = css({
  width: '100%',
  padding: `${spacings.grid_gap_basis_num * 3}px ${
    spacings.grid_gap_basis_num * 2
  }px`,
  boxShadow: '0px 0px 4px 0px #00000033',
  backgroundColor: colors.backgrounds.white,
  height: MOBILE_STEP_CONTAINER_HEIGHT,
  position: 'fixed',
  bottom: 0,
})

const mobileConfirmationModalStyle = css({
  '&.mindr-modal.small': {
    width: 'auto',
    margin: spacings.grid_gap_basis_num * 2,
    '& .mindr-modal-actions': {
      '& .buttons-container': {
        width: '100%',
      },
      '& .button': {
        flex: '50%',
      },
    },
  },
})

const mobileContentBoxStyle = css({
  width: '100%',
  padding: spacings.grid_gap_basis_num * 2,
  overflow: 'hidden',
  marginBottom: MOBILE_STEP_CONTAINER_HEIGHT,
})

const desktopContentBoxStyle = css({
  width: 1137,
})

export default function ApplicationStepWrapper({
  title,
  description,
  onNextStep = () => {},
  requiredFields = [],
  validations = [],
  onValidate = () => '',
  children,
  className = '',
}: ApplicationStepWrapperProps): JSX.Element {
  const {
    updateRawFormData,
    incrementStep,
    currentStep,
    maxSteps,
    onBackStep,
    submitApplicationUrl,
    formData,
    setErrors,
    isMobile,
  } = useContext(ApplicationFormContext)
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false)
  const isLastPage = useMemo(
    () => currentStep === maxSteps,
    [currentStep, maxSteps],
  )

  /**
   * Validates the form data based on the validations provided.
   * @param formData - The form data to validate.
   * @param validation - The validation object to use.
   */
  const validate = useCallback(
    (formData: FormData, validation: IFieldValidation) => {
      let value = formData.get(validation.fieldName) as string
      if (validation.prefilter) {
        value = validation.prefilter(value)
        formData.set(validation.fieldName, value)
      }

      let errors: Record<string, string[]> = {}
      if (validation.required && !value) {
        const msg = validation.requiredFailureMessage
          ? validation.requiredFailureMessage
          : `${validation.fieldDisplayName} is required.`
        errors = addError(errors, validation.fieldName, msg)
      }
      if (validation.pattern) {
        try {
          const regex = new RegExp(validation.pattern)
          if (!value.match(regex)) {
            const msg = validation.patterFailureMessage
              ? validation.patterFailureMessage
              : `${validation.fieldDisplayName} does not match the pattern.`
            errors = addError(errors, validation.fieldName, msg)
          }
        } catch (e) {
          console.log('PATTERN ERROR', e)
        }
      }

      if (Object.keys(errors).length) {
        setErrors(errors)
        return false
      }

      setErrors({})
      return true
    },
    [onNextStep, updateRawFormData, incrementStep],
  )

  const validatePage = useCallback(() => {
    const form = document.getElementById('mentorship_application_form')
    const newFormData = new FormData(form as HTMLFormElement)

    if (
      !requiredFields.every(
        (requiredField) =>
          Array.from(newFormData.keys()).includes(requiredField) &&
          newFormData.get(requiredField) !== '' &&
          newFormData.get(requiredField) !== null &&
          newFormData.get(requiredField) !== undefined &&
          newFormData.get(requiredField) !== 'false',
      )
    ) {
      window.flash(
        'The answer to this question is required in order to progress your application',
        'alert',
      )
      return false
    }

    if (!!onValidate) {
      const validateErrorMessage = onValidate()
      if (!!validateErrorMessage) {
        window.flash(validateErrorMessage, 'alert')
        return false
      }
    }

    let hasProblems = false
    validations.forEach((validation) => {
      if (!validate(newFormData, validation)) {
        hasProblems = true
      }
    })
    if (hasProblems) {
      window.flash(
        'Please correct the errors before moving to the next page.',
        'alert',
      )
      return false
    }

    return true
  }, [requiredFields, onValidate, validations, validate])

  /**
   * Executes when user clicks on "Next" to progress to the next step of the form.
   * There are 3 things this function do:
   * 1. execute any function we might want to call i.e. setting strength categories in context
   * 2. collect formData from form on current page
   * 3. increment step
   */
  const onNext = useCallback(
    async (lastStep: boolean) => {
      const form = document.getElementById('mentorship_application_form')
      const newFormData = new FormData(form as HTMLFormElement)

      const valid = validatePage()

      if (!valid) {
        return
      }

      if (!!onNextStep) {
        onNextStep()
      }
      const updatedRawFormData = updateRawFormData(newFormData)

      if (lastStep) {
        try {
          await Axios.post(submitApplicationUrl, {
            authenticity_token: window.authenticity_token,
            mentorship_program_application: {
              form_data: updatedRawFormData,
            },
            user: {
              expertise: formData['expertise'],
              org_years: formData['org-years'],
              industry_years: formData['industry-years'],
              linkedin_url: formData['linkedin-url'],
            },
          })
        } catch (error) {
          window.flash(
            `Something went wrong trying to save application data: ${error}`,
            'alert',
          )
        }
      }

      incrementStep()
    },
    [onNextStep, updateRawFormData, incrementStep, validatePage],
  )

  const onBack = useCallback(() => {
    const form = document.getElementById('mentorship_application_form')
    const newFormData = new FormData(form as HTMLFormElement)
    updateRawFormData(newFormData)
    onBackStep()
  }, [onBackStep, updateRawFormData])

  const progressBarStyle = [defaultProgressBarStyle]

  if (isMobile) {
    progressBarStyle.push(mobileProgressBarStyle)
  }

  const onSubmit = useCallback(() => {
    const valid = validatePage()
    if (!valid) {
      return
    }
    setOpenSubmitConfirmation(true)
  }, [validatePage, setOpenSubmitConfirmation])

  return (
    <MentorshipApplicationWrapper
      css={isMobile ? applicationWrapperStyle : null}
      className={className}>
      <>
        {isMobile && (
          <ProgressBar
            css={progressBarStyle}
            completed={(currentStep / maxSteps) * 100}
          />
        )}
        <div
          css={isMobile ? mobileContentBoxStyle : desktopContentBoxStyle}
          className="content-box">
          <div
            className="step-question"
            css={{
              maxWidth: 1161,
              marginBottom: spacings.grid_gap_basis_num * 3,
            }}>
            <SectionTitle
              css={{
                color: colors.teal_dark,
                marginBottom: spacings.grid_gap_basis_num * 2,
              }}>
              Mindr Mentorship Exchange Candidate Questionnaire
            </SectionTitle>
            <div
              css={{
                marginBottom: spacings.grid_gap_basis,
              }}>
              {title instanceof String || typeof title === 'string' ? (
                <h2>{title}</h2>
              ) : (
                title
              )}
            </div>
            {description &&
              (description instanceof String ||
              typeof description === 'string' ? (
                <Paragraph>{description}</Paragraph>
              ) : (
                description
              ))}
          </div>
          <form id="mentorship_application_form">{children}</form>
          <ConfirmationModal
            css={isMobile ? mobileConfirmationModalStyle : null}
            isOpen={openSubmitConfirmation}
            onConfirm={() => onNext(true)}
            onCancel={() => setOpenSubmitConfirmation(false)}
            cancelButton="back"
            submitButton={isMobile ? 'submit' : 'submit application'}
            title={
              isMobile
                ? 'Submit application'
                : 'Are you sure you are ready to submit your form?'
            }>
            Are you sure you want to submit this application form? This action
            cannot be undone.
          </ConfirmationModal>
        </div>
        <Container
          direction="column"
          css={isMobile ? mobileStepContainer : defaultStepContainer}>
          <Container
            css={
              isMobile
                ? css({
                    justifyContent: 'space-between',
                    width: '100%',
                  })
                : null
            }>
            <Button variant={Variant.SECONDARY} onClick={onBack} type="button">
              back
            </Button>
            {!isMobile && (
              <ProgressBar
                css={{
                  width: 857,
                }}
                completed={(currentStep / maxSteps) * 100}
              />
            )}
            <Button
              variant={Variant.PRIMARY}
              onClick={isLastPage ? onSubmit : () => onNext(false)}
              type="button">
              {isLastPage ? 'submit' : 'next'}
            </Button>
          </Container>
          {!isMobile && (
            <Paragraph>
              {currentStep} of {maxSteps}
            </Paragraph>
          )}
        </Container>
      </>
    </MentorshipApplicationWrapper>
  )
}
