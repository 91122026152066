import {
  ICommunity,
  IMentorshipApplicationLabel,
  IMentorshipExchangePackage,
} from 'types'
import {
  IMentorshipActionUrls,
  IMentorshipApplicantCounts,
  IMentorshipApplicantTables,
  MentorshipExchangeDetailsContextProvider,
} from './MentorshipExchangeDetailsContext'
import { IOption } from 'components/Inputs/Select'
import MatchResultsPageComponent from './MatchResultsPageComponent'
import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'

interface MatchResultsPageProps {
  tables: IMentorshipApplicantTables
  exchange: IMentorshipExchangePackage
  counts: IMentorshipApplicantCounts
  urls?: IMentorshipActionUrls
  labels?: IMentorshipApplicationLabel[]
  possibleCohorts?: Partial<ICommunity>[]
  boostList?: IOption[]
  seniorityList?: IOption[]
  expertiseList?: IOption[]
  userData?: {
    locations: any[]
    departments: any[]
  }
}

const tableCss = css({
  'table tr:hover td:not(.empty-records)': {
    backgroundColor: colors.backgrounds.gray,
  },
})

export default function MatchResultsPage({
  tables,
  exchange,
  counts,
  urls = {},
  labels = [],
  possibleCohorts = [],
  boostList,
  seniorityList,
  expertiseList,
  userData,
}: MatchResultsPageProps): JSX.Element {
  return (
    <MentorshipExchangeDetailsContextProvider
      exchange={exchange}
      tables={tables}
      counts={counts}
      urls={urls}
      labels={labels}
      possibleCohorts={possibleCohorts}
      boostList={boostList}
      seniorityList={seniorityList}
      expertiseList={expertiseList}
      userData={userData}>
      <MatchResultsPageComponent css={tableCss} />
    </MentorshipExchangeDetailsContextProvider>
  )
}
